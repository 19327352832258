header {
  box-shadow: 0px 3px 6px #00000029;
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 999;
}

.subCatDropDown:hover {
  display: "show";
  background: green;
}

.subCatDropDown {
  background: #00509d;
  position: relative;
  /* display: "none";
  opacity: 0; */
}

.subCatDrop {
  margin: 10px;
  border-color: white;
  text-decoration: none;
}

.subCatDrop:focus {
  border: none;
}
.subCatDrop:hover {
  border: none;
}

.widthSet {
  width: 70px !important;
  margin: 10px;
}
.subTest {
  margin-top: -20px;
  margin-left: 30px;
}



.mysubmitForm {
  background: transparent;
  color: #00509d;
  margin-top: 11px;
  border: 0;
  border-radius: 0;
  padding: 0;
  font-weight: bold;
  text-decoration: underline;
  font-size: 24px;
}

.activeColor {
  color: blue;
}

.blogPagin_class{
  display: flex;
  flex-direction: row;
 flex-wrap: nowrap !important;
}

.text_overflow{
  text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    height: 50px;
    margin-right: 10px;
}

.examBannrContWrp {
  max-width: 635px;
}

/* 
.listMenuswrp {
  overflow: auto;
  height: calc(100vh);
  left: 0;
  right: 0;
  position: fixed !important;
  width: 100%;
  top: 0;
}

.menuDropdown {
  position: absolute;
  left: calc(100vw - 1200px);
  top: 22px;
  bottom: auto;
  transform: translate(-50%, 0);
} */